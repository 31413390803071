<template>
  <div class="mainBox">
    <div class="titleBox">
      <div class="contentBox">
        <div class="titleName" v-if="personalityType">
          {{ personalityType }}
        </div>
        <div class="titleText">经系统AI匹配 三组学科最适合你</div>
      </div>
    </div>
    <div class="report-table">
      <div class="head">
        <div class="top">学科兴趣度</div>
        <div class="bottom">总分100分</div>
      </div>
      <div class="content" v-if="situationList">
        <ul class="box">
          <li class="list" v-for="(item, index) in situationList" :key="index">
            <div class="subheadBox">
              <div class="subhead">
                {{ item.Name }}
              </div>
            </div>
            <div class="range">
              {{ item.number }}
              <div
                class="rangeactive"
                :class="{
                  rangeright: item.number >= 60,
                }"
                :style="{
                  width: item.number + '%',
                }"
              >
                {{ item.number }}
              </div>
            </div>
          </li>
        </ul>
        <template v-if="SelSubjectMainData.ResultAnswers">
          <div
            v-for="(item, index) in SelSubjectMainData.ResultAnswers"
            :key="index"
          >
            <template
              v-if="
                (ResultAnswersShowMore &&
                  index <= SelSubjectMainData.ResultAnswers.length - 1) ||
                (!ResultAnswersShowMore && index === 0)
              "
            >
              <div class="contentTitle" v-if="index > 0">
                <div>备选{{ index }}</div>
                <div>{{ item.Answer }}</div>
              </div>
              <div class="bodyBox" v-if="item.Character">
                <div class="bodyTitle">学科特点</div>
                <div class="bodyItem" v-html="item.Character"></div>
              </div>
              <div
                class="bodyBox"
                v-if="item.StatisticData && item.StatisticData.length > 0"
              >
                <div class="bodyTitle">覆盖率</div>
                <div class="titleItem">
                  在该省份的专业选考要求中满足要求的占比
                </div>
                <div class="titleItem percentAll">
                  可报专业比例：{{ yuantofen(item.PercentAboveAll, 100) }}%
                </div>
                <div
                  class="bodyArr"
                  v-for="(ite, index1) in item.StatisticDataSub"
                  :key="index1"
                >
                  <div class="bodyArrItem bodyArrItemtitle">
                    <span>{{ ite.Name }}</span>
                    <span class="span">{{ yuantofen(ite.Percent, 100) }}%</span>
                  </div>
                  <div
                    class="bodyArrItem"
                    v-for="(it, index2) in ite.ChildNodes"
                    :key="index2"
                  >
                    <div>
                      {{ it.Name }}
                      <div>{{ yuantofen(it.Percent, 100) }}%</div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    item.StatisticData.length > 3 &&
                    item.StatisticDataSub.length <= 3
                  "
                >
                  <div class="lookMore" @click="lookMoreStatistic(index)">
                    查看更多
                  </div>
                </div>
              </div>
              <div class="bodyBox" v-if="item.Pros || item.Cons">
                <div class="bodyTitle">选科优劣势</div>
                <div class="bodyItem ProsBox" v-if="item.Pros">
                  <div class="itemTitle">优势</div>
                  <div v-html="item.Pros"></div>
                </div>
                <div class="bodyItem ProsBox" v-if="item.Cons">
                  <div class="itemTitle">劣势</div>
                  <div v-html="item.Cons"></div>
                </div>
              </div>
            </template>
          </div>
          <div v-if="!ResultAnswersShowMore">
            <div class="lookMore" @click="lookMoreResultAnswers">
              查看更多备选组合
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api/config'

// import { TestSelSubjectInfo } from "@/api/test/test";
import { getStore } from "@/utils/utils";
export default {
  name: "test-result",
  data() {
    return {
      clientWidth: document.body.clientWidth,
      //personality test
      SelSubjectMainData: {},
      personalityType: "",
      situationList: [
        {
          Name: "物理",
          nunber: 0,
        },
        {
          Name: "历史",
          nunber: 0,
        },
        {
          Name: "地理",
          nunber: 0,
        },
        {
          Name: "政治",
          nunber: 0,
        },
        {
          Name: "化学",
          nunber: 0,
        },
        {
          Name: "生物",
          nunber: 0,
        },
      ],
      StatisticShowMore: false,
      ResultAnswersShowMore: false,
    };
  },
  computed: {},
  watch: {
    $route: function () {
      this.init();
    },
  },
  created() {},
  mounted() {
    this.init();
    // window.history.back(-3)
  },
  methods: {
    yuantofen(arg1, arg2) {
      var m = 0;
      var s1 = arg1.toString();
      var s2 = arg2.toString();
      try {
        m += s1.split(".")[1].length;
      } catch (e) {}
      try {
        m += s2.split(".")[1].length;
      } catch (e) {}

      return (
        (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
        Math.pow(10, m)
      );
    },
    lookMoreStatistic(index) {
      if (index !== undefined) {
        this.$set(
          this.SelSubjectMainData.ResultAnswers[index],
          "StatisticDataSub",
          this.SelSubjectMainData.ResultAnswers[index].StatisticData
        );
      } else {
        this.SelSubjectMainData.ResultAnswers.forEach((item) => {
          item.StatisticDataSub = item.StatisticData;
        });
      }
      this.$forceUpdate();
    },
    lookMoreResultAnswers() {
      this.ResultAnswersShowMore = true;
    },
    lookMore() {
      this.lookMoreStatistic();
      this.lookMoreResultAnswers();
    },
    init() {
      let loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0,0,0,0.5)'
      })
      
      let tempInfo = getStore('LoginData')

      if (!tempInfo) {
        this.$message.info('请先登录！')
        return
      }

      API.User.TestSelSubjectInfo({
        UserId: JSON.parse(tempInfo).UserId,
        SubjectRanking: this.$ls.get("test-subjectSort"),
        AnswerList: this.$ls.get("test-newExamChooseAnswer"),
        ProvinceId: this.$ls.get("test-newExamChoose"),
      })
        .then((res) => {
          this.SelSubjectMainData = res.data;
          let temp = res.data.ResultScore;
          if (
            this.SelSubjectMainData.ResultAnswers &&
            this.SelSubjectMainData.ResultAnswers.length > 0
          ) {
            this.SelSubjectMainData.ResultAnswers.forEach((item) => {
              item.StatisticDataSub = [];
              item.StatisticData.forEach((ite, index) => {
                if (index < 3) {
                  item.StatisticDataSub.push(ite);
                }
              });
            });
            this.personalityType =
              this.SelSubjectMainData.ResultAnswers[0].Answer;
          }

          this.situationList.forEach((item) => {
            Object.keys(temp).forEach((sItem) => {
              if (item.Name === sItem) {
                item.number = temp[sItem];
              }
            });
          });
          loading.close()
        })
        .catch((err) => {
          loading.close()
        });
    },
  },
};
</script>

<style scoped lang="less">
@media print {
}
.mainBox {
  .titleBox {
    display: flex;
    margin-bottom: 20px;
    color: #fff;
    .contentBox {
      text-align: center;
      width: 100%;
      .titleName {
        font-size: 26px;
        font-weight: 400;
        line-height: 36px;
      }
      .titleText {
        margin: 16px 0px;
        font-size: 14px;
        line-height: 20px;
        height: auto;
      }
    }
  }

  .report-table {
    background: #fff;
    border-radius: 20px;
    padding: 20px;
    .OrgNamebox {
      text-align: center;
      font-size: 10px;
      color: #a8a8bb;
      margin-bottom: 10px;
    }
    .head {
      margin-bottom: 20px;
      text-align: center;
      .top {
        height: 22px;
        font-size: 20px;
        font-weight: bold;
        line-height: 22px;
        margin-bottom: 16px;
      }
      .bottom {
        font-size: 14px;
        line-height: 20px;
        color: #a8a8bb;
      }
    }
    .content {
      .box {
        .list {
          margin-bottom: 16px;
          .subheadBox {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 4px;
            font-size: 12px;
            .subhead {
              color: #333;
            }
            .subactive {
              color: #ff823a;
            }
          }
          .range {
            width: 100%;
            height: 20px;
            line-height: 20px;
            text-align: center;
            background: #f9f9f9;
            border-radius: 15px;
            font-size: 6px;
            color: transparent;
            position: relative;
            .rangeactive {
              text-align: right;
              position: absolute;
              top: 0;
              font-size: 14px;
              height: 20px;
              color: #fff;
              background: linear-gradient(to right, rgba(var(--themecolor),0.45), rgba(var(--themecolor),0.75));
              border-radius: 15px;
              padding-right: 12px;
            }
            .rangeright {
              box-shadow: 4px 4px 10px rgba(255, 115, 93, 0.4);
              background: linear-gradient(90deg, #ff823a 0%, #ff735d 100%);
            }
          }
        }
      }
      .contentTitle {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        line-height: 23px;
        color: rgba(var(--themecolor),0.75);
      }

      .bodyBox {
        padding-top: 12px;
        color: #2a2b4e;
        .bodyTitle {
          position: relative;
          font-size: 16px;
          font-weight: bold;
          margin: 0 0 12px 16px;
          &::before {
            position: absolute;
            left: -16px;
            content: "";
            width: 8px;
            height: 16px;
            background: rgba(var(--themecolor),0.75);
            border-radius: 4px;
          }
        }

        .bodyItem {
          line-height: 24px;
          font-size: 14px;
        }
        .ProsBox {
          background: #f9f9f9;
          padding: 20px;
          margin-bottom: 20px;
          .itemTitle {
            height: 45px;
            font-size: 16px;
            font-weight: bold;
            line-height: 40px;
            color: #2a2b4e;
            text-align: center;
          }
        }
        .bodyArr {
          display: flex;
          flex-wrap: wrap;
          background: #f9f9f9;
          margin-bottom: 12px;
          border-radius: 10px;
        }
        .bodyArrItem {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 10px 4px;
          height: 60px;
          width: 88px;
          background: #fff;
          border-radius: 10px;
          line-height: 16px;
          font-size: 12px;
          text-align: center;
          color: #2a2b4e;
        }
        .bodyArrItemtitle {
          width: 100%;
          height: 60px;
          background: rgba(34, 178, 106, 0.2);
          border-radius: 10px;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          margin: 0px 0px 10px;
          color: #22b26a;
          line-height: 60px;
          & > .span {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.lookMore {
  width: 160px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  color: rgba(var(--themecolor),0.75);
  text-align: center;
  cursor: pointer;
  margin: 0 auto;
}
.titleItem {
  height: 33px;
  font-size: 12px;
  line-height: 33px;
  color: #a8a8bb;
}
.percentAll {
  text-align: center;
}
</style>
