<template>
  <div class="mainBox">
    <div v-show="IsVipUser==true">
      <div class="titleText">
      <div class="messgeText">
        本测试结果依据霍兰德职业测试模型，结合新高考的选科特点和志愿填报数据，按照《中华人民共和国职业分类大典》的职业分类。
      </div>
      <div>下方显示的是您的职业兴趣倾向</div>
      <div>
        1、查看你得分最高的三项的描述。哪些是对你的职业价值观比较适合的工作？你应该有意识的往这些方向选择自己的职业。
      </div>
      <div>
        2、请看看得分最低的一个分项，那是你最不喜欢和擅长的一些工作，你应该有意识的避免它们。
      </div>
    </div>
    <div id="echartBox" class="titleBox"></div>
    <div class="report-table">
      <div class="head">
        <div class="top">测试模型及得分</div>
        <div class="bottom">
          分数越高（大于等于7分），说明性格特征越明显，对职业的影响就越大，测试可参考性就越大；分数低（小于等于3分）,说明此性格对职业选择基本没有影响。如果所有分数都低于6分，请直接忽视本次测试。
        </div>
      </div>
      <div class="content" v-if="hollandMainData">
        <ul class="box">
          <li
            class="list"
            v-for="item in testResultTableData"
            :key="item.order"
          >
            <div class="subheadBox">
              <div class="subhead">
                {{ item.name }}
              </div>
            </div>
            <div class="range">
              {{ item.order }}
              <div
                class="rangeactive"
                :class="{
                  rangeright: item.score >= 7.5,
                }"
                :style="{
                  width: item.percentage + '%',
                }"
              >
                {{ item.score }}分
              </div>
            </div>
          </li>
        </ul>
        <div
          class="centerText"
          v-if="
            hollandMainData.ResultAnswers &&
            hollandMainData.ResultAnswers.length > 0
          "
        >
          根据你的选择，你的性格特征可能为：
          <span
            v-for="(ite, index) in hollandMainData.ResultAnswers"
            :key="ite"
          >
            {{ ite }}型{{
              index === hollandMainData.ResultAnswers.length - 1 ? "。" : "、"
            }}
          </span>
        </div>
        <div v-if="typeTestList.length > 0">
          <div
            class="typeTextBox"
            v-for="(item, index) in typeTestList"
            :key="index"
          >
            <div class="typeTextTitle">{{ item.order }}型：</div>
            <div class="typeTextcontent">{{ item.content }}</div>
          </div>
        </div>
        <div class="includBox">
          <div class="bodyBox">
            <div class="title">六种类型的内在关系</div>
            <div class="text textActive">
              霍兰德所划分的六大类型，并非是并列的、有着明晰的边界的。他以六边形标示出六大类型的关系。
            </div>
          </div>
          <div class="imgeBox">
            <img class="imgtitle" src="@/assets/test/huoland-star.png" />
          </div>
          <div class="bodyBox">
            <div class="title">相邻关系</div>
            <div class="text">
              如
              RI、IR、IA、AI、AS、SA、SE、ES、EC、CE、RC及CR。属于这种关系的两种类型的个体之间共同点较多，现实型
              R 、研究型 I
              的人就都不太偏好人际交往，这两种职业环境中也都较少机会与人接触。
            </div>
          </div>
          <div class="bodyBox">
            <div class="title">相隔关系</div>
            <div class="text">
              如RA、RE、IC、IS、AR、AE、SI、SC、EA、ER、CI及CS，属于这种关系的两种类型个体之间共同点较相邻关系少。
            </div>
          </div>
          <div class="bodyBox">
            <div class="title">相对关系</div>
            <div class="text">
              在六边形上处于对角位置的类型之间即为相对关系，如RS、IE、AC、SR、EI、及CA即是，相对关系的人格类型共同点少，因此，一个人同时对处于相对关系的两种职业环境都兴趣很浓的情况较为少见。
            </div>
          </div>
        </div>
        <div v-for="(item, index) in hollandMainData.DataList" :key="index">
          <div class="titleName">{{ item.ResultCode }}型</div>
          <div class="bodyBox" v-if="item.Character">
            <div class="bodyTitle">性格特点</div>
            <div class="bodyItem" v-html="item.Character"></div>
          </div>
          <div class="bodyBox" v-if="item.ProfessionKeyWords">
            <div class="bodyTitle">职业关键词</div>
            <div class="bodyArrItem">
              {{ item.ProfessionKeyWords }}
            </div>
          </div>

          <div
            class="bodyBox"
            v-if="item.SpecialtiesBKList || item.SpecialtiesZKList"
          >
            <div class="bodyTitle">匹配专业</div>
            <div
              class="bodyArr"
              v-if="item.SpecialtiesBKList && item.SpecialtiesBKList.length > 0"
            >
              <div class="bodyArrItem bodyArrItemtitle">本科专业</div>
              <div
                class="bodyArrItem"
                v-for="(item, index) in item.SpecialtiesBKList"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
            <div
              class="bodyArr"
              v-if="item.SpecialtiesZKList && item.SpecialtiesZKList.length > 0"
            >
              <div class="bodyArrItem bodyArrItemtitle">专科专业</div>
              <div
                class="bodyArrItem"
                v-for="(item, index) in item.SpecialtiesZKList"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
          </div>
          <div class="bodyBox" v-if="item.DevelopmentDirection">
            <div class="bodyTitle">发展方向</div>
            <div class="bodyArrItem" v-html="item.DevelopmentDirection"></div>
          </div>
          <div class="bodyBox" v-if="item.ProfessionList">
            <div class="bodyTitle">适合职业</div>
            <div class="bodyArr">
              <div
                class="bodyArrItem"
                v-for="(item, index) in item.ProfessionList"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          提醒：职业兴趣提供专业选择的大致方向，请结合高考成绩、就业前景、院校实力、所在地区等因素，综合确定你的高考志愿！研究推出了“选科与选专业”系列工具，为报考志愿的选择提供了有益参考，推荐考生和家长使用！
        </div>
      </div>
    </div>
    </div>

    <div class="buy_vip_box" v-show="IsVipUser==false">
        <div class="btitle">您还不是VIP用户，暂无权限查看测评报告！</div>
        <div class="open_btn" @click.prevent="$router.push('/openvip');">开通 VIP</div>
    </div>
  </div>
</template>

<script>
import API from '@/api/config'
// import { TestHollandInfo } from "@/api/test/test";

export default {
  name: "test-result",
  data() {
    return {
      IsVipUser:null,
      clientWidth: document.body.clientWidth,
      //holland test
      hollandMainData: {},
      testResultTableData: [
        {
          icon: require("@/assets/test/research.png"),
          type: "研究型",
          score: 0,
          percentage: 0,
          order: "I",
          name: "I：研究型",
          max: 10,
          content:
            "(Investigative)又译调查型。具有这类倾向的个体，喜欢理论思维或偏爱数理统计工作，对于解决抽象性问题具有极大的热情。他们通常倾向于通过思考、分析解决难题，而不一定落实到具体操作。喜欢具有创造性、挑战性的工作，不太喜欢固定程式的任务。对于人员的领导及人际交往也非情所愿，独立倾向明显。",
        },
        {
          icon: require("@/assets/test/cup.png"),
          type: "社会型",
          score: 0,
          percentage: 0,
          order: "S",
          name: "S：社会型",
          max: 10,
          content:
            "(Social)具有此类倾向的个体，喜欢与人为对象的工作。他们通常言语能力优于数理能力，善于言谈，乐于与人相处，给人提供帮助，具有人道主义倾向，责任心也较强。习惯于与人商讨或调整人际关系来解决面临的问题。不太喜欢以机械和物品为对象的工作",
        },
        {
          icon: require("@/assets/test/tree.png"),
          type: "常规型",
          score: 0,
          percentage: 0,
          order: "C",
          name: "C：常规型",
          max: 10,
          content:
            "(conventional)又译事务型、传统型。具有这类倾向的个体，喜欢高度有序、要求明晰的工作，对于规则模糊、自由度大的工作不太适应。不喜欢主动决策，习惯于服从，一般较为忠诚、可靠，偏保守。与人工作中的交往会保持一定的距离。工作仔细、有毅力。对社会地位、社会评价比较在意，通常愿意在大型机构做一般性工作。",
        },
        {
          icon: require("@/assets/test/umbrella.png"),
          type: "现实型",
          score: 0,
          percentage: 0,
          order: "R",
          name: "R：现实型",
          max: 10,
          content:
            "(Realistic)又译技能型。具有这类倾向的个体，属于技术与运动取向。往往身体技能及机械协调能力较强，对机械与物体的关心比较强烈。稳健、务实，喜欢从事规则明确的活动及技术性工作，甚至热衷于亲自动手创造新事物。不善言谈，对于人际交往及人员管理、监督等活动不太感兴趣。",
        },
        {
          icon: require("@/assets/test/hammer.png"),
          type: "企业型",
          score: 0,
          percentage: 0,
          order: "E",
          name: "E：企业型",
          max: 10,
          content:
            "(Enterprising)又译经营型。具有这种兴趣倾向的个体，喜欢制定新的工作计划、事业规划以及设立新的组织，并积极地发挥组织的作用进行活动；喜欢影响、管理、领导他人；自信、支配欲、冒险性强。他们不喜欢具体精细、或需要长时间集中心智的工作。",
        },
        {
          icon: require("@/assets/test/art.png"),
          type: "艺术型",
          score: 0,
          percentage: 0,
          order: "A",
          name: "A：艺术型",
          max: 10,
          content:
            "(Artistic)具有此类倾向的个体，对具有创造、想象及自我表现空间的工作显示出明显偏好。他们和研究型倾向的个体相同之处在于创造倾向明显，对于结构化程度较高的任务及环境都不太喜欢，对于机械性及程式化的工作了无兴趣。也比较喜欢独立行事，不太合群。但两者所不同的是艺术倾向明显的个体好自我表现，重视自己的感性，直觉力较好，情绪变化较大。",
        },
      ],
      typeTestList: [],
    };
  },
  computed: {},
  watch: {
    $route: function () {
      this.init();
    },
  },
  created() {},
  mounted() {
    this.init();
    // window.history.back(-3)
  },
  methods: {
    num(param) {
      return param / 10;
    },
    setEcharts() {
      const chartDom = document.getElementById("echartBox");
      const myChart = this.$echarts.init(chartDom);
      let option;
      let dataArr = [];
      this.testResultTableData.forEach((TItem) => {
        dataArr.push(TItem.score);
      });
      option = {
        color: ["#444CF0"],
        radar: {
          // shape: 'circle',
          indicator: this.testResultTableData,
          splitArea: {
            areaStyle: {
              color: ["#fff", "#F3F8FC"],
            },
          },
          name: {
            textStyle: {
              color: "#fff",
            },
          },
          axisLine: {
            lineStyle: {
              color: "#F9F9F9",
            },
          },
          splitLine: {
            lineStyle: {
              color: "#F9F9F9",
            },
          },
        },
        series: [
          {
            type: "radar",
            data: [
              {
                value: dataArr,
                areaStyle: {
                  color: "rgba(68, 76, 240, 0.3)",
                },
                // label: {
                //   show: true,
                //   formatter: function (params) {
                //     return params.value + "分";
                //   },
                // },
              },
            ],
          },
        ],
      };
      option && myChart.setOption(option);
    },
    init() {
      let loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0,0,0,0.5)'
      })
      let MBTIAnswer = this.$ls.get("testAnswer");
      //霍兰德测试

      API.User.TestHollandInfo({
        AnswerList: MBTIAnswer,
      })
        .then((res) => {
          if(res.code==0){
            this.IsVipUser = true;
            this.hollandMainData = res.data;
            let temp = res.data.ResultScore;
            this.typeTestList = [];
            const typeArr = this.hollandMainData.ResultAnswers.join("").split("");
            typeArr.forEach((Item) => {
              const index = this.typeTestList.findIndex(
                (ite) => ite.order === Item
              );
              this.testResultTableData.forEach((TItem) => {
                if (Item === TItem.order && index === -1) {
                  this.typeTestList.push(TItem);
                }
              });
            });
            this.testResultTableData.forEach((TItem) => {
              for (let i in temp) {
                if (i === TItem.order) {
                  TItem.score = temp[i];
                  TItem.percentage = temp[i] / 1.5 * 10;
                }
              }
            });
            this.setEcharts();
          }
          else if(res.code==113){
            this.IsVipUser = false;
          }
          else{
            this.$message.error(res.message);
          }
          loading.close()
        })
        .catch((err) => {
          loading.close()
        });
    },
  },
};
</script>

<style scoped lang="less">
.mainBox {
  .titleBox {
    margin: 0 auto 20px;
    height: 270px;
    width: 350px;
  }
  .titleName {
    font-size: 26px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    margin-top: 20px;
  }
  .titleText {
    margin: 20px 0px;
    font-size: 14px;
    line-height: 24px;
    height: auto;
    color: #fff;
    .messgeText {
      margin-bottom: 20px;
    }
  }
  .centerText {
    margin: 20px 0;
    line-height: 24px;
  }
  .report-table {
    background: #fff;
    border-radius: 20px;
    padding: 20px;
    .OrgNamebox {
      text-align: center;
      font-size: 10px;
      color: #a8a8bb;
      margin-bottom: 10px;
    }
    .head {
      margin-bottom: 20px;
      .top {
        height: 22px;
        font-size: 20px;
        font-weight: bold;
        line-height: 22px;
        text-align: center;
        margin-bottom: 16px;
      }
      .bottom {
        font-size: 14px;
        line-height: 20px;
        color: #a8a8bb;
      }
    }
    .content {
      .box {
        .list {
          margin-bottom: 16px;
          .subheadBox {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 4px;
            font-size: 12px;
            .subhead {
              color: #2a2b4e;
            }
            .subactive {
              color: #ff823a;
            }
          }
          .range {
            width: 100%;
            height: 20px;
            line-height: 20px;
            text-align: center;
            background: #f9f9f9;
            border-radius: 15px;
            font-size: 6px;
            color: transparent;
            position: relative;
            .rangeactive {
              text-align: right;
              position: absolute;
              top: 0;
              font-size: 14px;
              height: 20px;
              color: #fff;
              background: #a8a8bb;
              border-radius: 15px;
              padding-right: 12px;
            }
            .rangeright {
              background: linear-gradient(90deg, #ff823a 0%, #ff735d 100%);
              box-shadow: 4px 4px 10px rgba(255, 115, 93, 0.4);
            }
          }
        }
      }
      .bodyBox {
        padding-top: 12px;
        color: #2a2b4e;
        .bodyTitle {
          position: relative;
          font-size: 16px;
          font-weight: bold;
          margin: 0 0 12px 16px;
          &::before {
            position: absolute;
            left: -16px;
            content: "";
            width: 8px;
            height: 16px;
            background: #444cf0;
            border-radius: 4px;
          }
        }
        .bodyItem {
          line-height: 24px;
          font-size: 14px;
        }
        .bodyArr {
          display: flex;
          flex-wrap: wrap;
        }
        .bodyArrItem {
          display: inline-block;
          margin: 10px 4px;
          height: 32px;
          background: #f9f9f9;
          border-radius: 16px;
          padding: 0px 16px;
          line-height: 32px;
          font-size: 12px;
          text-align: center;
        }
        .bodyArrItemtitle {
          width: 100%;
          font-weight: bold;
          font-size: 14px;
          color: #a8a8bb;
        }
      }
    }
  }
}
.footer {
  color: #e84646;
  line-height: 24px;
  margin-top: 20px;
}
.includBox {
  background: #f9f9f9;
  padding: 20px;
  color: #2a2b4e;
  text-align: center;

  .imgeBox {
    margin: 0 auto;
    max-width: 530px;
  }
  .imgtitle {
    width: 100%;
    margin: 16px auto;
  }
  .bodyBox {
    .title {
      font-weight: bold;
      margin-bottom: 8px;
      text-align: center;
      font-size: 14px;
    }
    .titleActive {
      font-size: 16px;
    }
    .text {
      line-height: 24px;
      font-size: 12px;
    }
    .textActive {
      color: #a8a8bb;
    }
  }
}
.typeTextBox {
  margin-bottom: 16px;
  font-size: 12px;
  color: #2A2B4E;
  line-height: 20px;
  .typeTextTitle{
    font-weight: bold;
  }
}
.buy_vip_box{
    max-width: 1000px;
    margin: auto;
    min-height: 500px;
    .btitle{
        color: #fff;
        font-size: 26px;
        text-align: center;
        margin: 100px auto 50px;
    }
    .open_btn{
        width: 200px;
        height: 46px;
        line-height: 46px;
        color: rgba(var(--themecolor),0.85);
        background-color: #fff;
        font-size: 22px;
        letter-spacing: 5px;
        border-radius: 5px;
        margin: auto;
        text-align: center;
        font-weight: bold;
        margin-bottom: 50px;
        cursor: pointer;
    }
}
</style>
