<template>
  <div class="mainBox">
    <div v-show="IsVipUser==true">
      <div class="titleBox">
      <div class="contentBox">
        <div class="titleName" v-if="personalityType">
          {{ personalityType }}
        </div>
        <div class="titleText">
          <!-- 依据霍兰德职业测试模型，结合新高考的选科特点和志愿填报数据，按照2015版《中华人民共和国职业分类大典》的职业分类，本次测试计算机评估如下，谨供考生填报志愿参考。 -->
        </div>
      </div>
    </div>
    <div class="report-table">
      <div class="head">
        <div class="top">测试情况</div>
        <div class="bottom">总分100分</div>
      </div>
      <div class="content" v-if="situationList">
        <ul class="box">
          <li class="list" v-for="item in situationList" :key="item.order">
            <div class="subheadBox">
              <div class="subhead">
                {{ item.SType + "：" + item.SName }}
              </div>
            </div>
            <div class="range">
              {{ item.SOrder }}
              <div
                class="rangeactive"
                :class="{
                  rangeright: item.SScore >= 60,
                }"
                :style="{
                  width: item.SScore + '%',
                }"
              >
                {{ item.SScore }}
              </div>
            </div>
          </li>
        </ul>
        <div class="bodyBox" v-if="personalityMainData.ResultContent">
          <div
            class="bodyItem"
            v-html="personalityMainData.ResultContent"
          ></div>
        </div>
        <div class="bodyBox" v-if="personalityMainData.Character">
          <div class="bodyTitle">性格特点</div>
          <div class="bodyItem" v-html="personalityMainData.Character"></div>
        </div>
        <div class="bodyBox" v-if="personalityMainData.ProfessionKeyWords">
          <div class="bodyTitle">职业关键词</div>
          <div class="bodyArrItem">
            {{ personalityMainData.ProfessionKeyWords }}
          </div>
        </div>

        <div
          class="bodyBox"
          v-if="
            personalityMainData.SpecialtiesBKList ||
            personalityMainData.SpecialtiesZKList
          "
        >
          <div class="bodyTitle">匹配专业</div>
          <div class="bodyArr" v-if="personalityMainData.SpecialtiesBKList">
            <div class="bodyArrItem bodyArrItemtitle">本科专业</div>
            <div
              class="bodyArrItem"
              v-for="(item, index) in personalityMainData.SpecialtiesZKList"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
          <div class="bodyArr" v-if="personalityMainData.SpecialtiesZKList">
            <div class="bodyArrItem bodyArrItemtitle">专科专业</div>
            <div
              class="bodyArrItem"
              v-for="(item, index) in personalityMainData.SpecialtiesBKList"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div class="bodyBox" v-if="personalityMainData.DevelopmentDirection">
          <div class="bodyTitle">发展方向</div>
          <div
            class="bodyArrItem"
            v-html="personalityMainData.DevelopmentDirection"
          ></div>
        </div>
        <div class="bodyBox" v-if="personalityMainData.ProfessionList">
          <div class="bodyTitle">适合职业</div>
          <div class="bodyArr">
            <div
              class="bodyArrItem"
              v-for="(item, index) in personalityMainData.ProfessionList"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>

    <div class="buy_vip_box" v-show="IsVipUser==false">
        <div class="btitle">您还不是VIP用户，暂无权限查看测评报告！</div>
        <div class="open_btn" @click.prevent="$router.push('/openvip');">开通 VIP</div>
    </div>
  </div>
</template>

<script>
import API from '@/api/config'
// import { TestPersonalityInfo } from "@/api/test/test";

export default {
  name: "test-result",
  data() {
    return {
      IsVipUser:null,
      clientWidth: document.body.clientWidth,
      //personality test
      personalityMainData: {},
      personalityType: "",
      situationList: [
        {
          SType: "第一型",
          SName: "完美型",
          SScore: "60",
          SStarNum: "5",
          SOrder: "A",
        },
        {
          SType: "第二型",
          SName: "助人型",
          SScore: "60",
          SStarNum: "5",
          SOrder: "B",
        },
        {
          SType: "第三型",
          SName: "成就型",
          SScore: "60",
          SStarNum: "5",
          SOrder: "C",
        },
        {
          SType: "第四型",
          SName: "感觉型",
          SScore: "60",
          SStarNum: "5",
          SOrder: "D",
        },
        {
          SType: "第五型",
          SName: "思想型",
          SScore: "60",
          SStarNum: "5",
          SOrder: "E",
        },
        {
          SType: "第六型",
          SName: "忠诚型",
          SScore: "60",
          SStarNum: "5",
          SOrder: "F",
        },
        {
          SType: "第七型",
          SName: "活跃型",
          SScore: "60",
          SStarNum: "5",
          SOrder: "G",
        },
        {
          SType: "第八型",
          SName: "领袖型",
          SScore: "60",
          SStarNum: "5",
          SOrder: "H",
        },
        {
          SType: "第九型",
          SName: "和平型",
          SScore: "60",
          SStarNum: "5",
          SOrder: "I",
        },
      ],
    };
  },
  computed: {},
  watch: {
    $route: function () {
      this.init();
    },
  },
  created() {},
  mounted() {
    this.init();
    // window.history.back(-3)
  },
  methods: {
    num(param) {
      return param / 10;
    },
    init() {
      let loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0,0,0,0.5)'
      })
      let MBTIAnswer = this.$ls.get("testAnswer");
      //人格测试
      API.User.TestPersonalityInfo({
        AnswerList: MBTIAnswer,
      })
        .then((res) => {
          if(res.code==0){
            this.IsVipUser=true;
            this.personalityMainData = res.data;
            let temp = res.data.ResultScore;
            this.situationList.forEach((item) => {
              if (this.personalityMainData.ResultCode === item.SOrder) {
                this.personalityType = item.SType + " " + item.SName;
              }
              Object.keys(temp).forEach((sItem) => {
                if (item.SOrder === sItem) {
                  item.SScore = temp[sItem];
                  item.SStarNum = Math.floor(temp[sItem] / 10);
                }
              });
            });
          }
          else if(res.code==113){
            this.IsVipUser=false;
          }
          else{
            this.$message.error(res.message);
          }
          loading.close()
        })
        .catch((err) => {
          loading.close()
        });
    },
  },
};
</script>

<style scoped lang="less">
@media print {
}
.mainBox {
  .titleBox {
    display: flex;
    margin-bottom: 20px;
    color: #fff;
    .contentBox {
      width: 100%;
      .titleName {
        font-size: 26px;
        font-weight: 400;
        line-height: 36px;
        text-align: center;
      }
      .titleText {
        margin: 16px 0px;
        font-size: 14px;
        line-height: 20px;
        height: auto;
      }
    }
  }

  .report-table {
    background: #fff;
    border-radius: 20px;
    padding: 20px;
    .OrgNamebox {
      text-align: center;
      font-size: 10px;
      color: #a8a8bb;
      margin-bottom: 10px;
    }
    .head {
      margin-bottom: 20px;
      text-align: center;
      .top {
        height: 22px;
        font-size: 20px;
        font-weight: bold;
        line-height: 22px;
        margin-bottom: 16px;
      }
      .bottom {
        font-size: 14px;
        line-height: 20px;
        color: #a8a8bb;
      }
    }
    .content {
      .box {
        .list {
          margin-bottom: 16px;
          .subheadBox {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 4px;
            font-size: 12px;
            .subhead {
              color: #2a2b4e;
            }
            .subactive {
              color: #ff823a;
            }
          }
          .range {
            width: 100%;
            height: 20px;
            line-height: 20px;
            text-align: center;
            background: #f9f9f9;
            border-radius: 15px;
            font-size: 6px;
            color: transparent;
            position: relative;
            .rangeactive {
              text-align: right;
              position: absolute;
              top: 0;
              font-size: 14px;
              height: 20px;
              color: #fff;
              background: #a8a8bb;
              border-radius: 15px;
              padding-right: 12px;
            }
            .rangeright {
              box-shadow: 4px 4px 10px rgba(255, 115, 93, 0.4);
              background: linear-gradient(90deg, #ff823a 0%, #ff735d 100%);
            }
          }
        }
      }
      .bodyBox {
        padding-top: 12px;
        color: #2a2b4e;
        .bodyTitle {
          position: relative;
          font-size: 16px;
          font-weight: bold;
          margin: 0 0 12px 16px;
          &::before {
            position: absolute;
            left: -16px;
            content: "";
            width: 8px;
            height: 16px;
            background: #444cf0;
            border-radius: 4px;
          }
        }
        .bodyItem {
          line-height: 24px;
          font-size: 14px;
        }
        .bodyArr {
          display: flex;
          flex-wrap: wrap;
        }
        .bodyArrItem {
          display: inline-block;
          margin: 10px 4px;
          height: 32px;
          background: #f9f9f9;
          border-radius: 16px;
          padding: 0px 16px;
          line-height: 32px;
          font-size: 12px;
          text-align: center;
        }
        .bodyArrItemtitle {
          width: 100%;
          font-weight: bold;
          font-size: 14px;
          color: #a8a8bb;
        }
      }
    }
  }
}
.footer {
  color: #e84646;
  line-height: 24px;
  margin-top: 20px;
}
.buy_vip_box{
    max-width: 1000px;
    margin: auto;
    min-height: 500px;
    .btitle{
        color: #fff;
        font-size: 26px;
        text-align: center;
        margin: 100px auto 50px;
    }
    .open_btn{
        width: 200px;
        height: 46px;
        line-height: 46px;
        color: rgba(var(--themecolor),0.85);
        background-color: #fff;
        font-size: 22px;
        letter-spacing: 5px;
        border-radius: 5px;
        margin: auto;
        text-align: center;
        font-weight: bold;
        margin-bottom: 50px;
        cursor: pointer;
    }
}
</style>
