var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mainBox"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.IsVipUser==true),expression:"IsVipUser==true"}]},[_c('div',{staticClass:"titleBox"},[_c('div',{staticClass:"contentBox"},[(_vm.personalityType)?_c('div',{staticClass:"titleName"},[_vm._v(" "+_vm._s(_vm.personalityType)+" ")]):_vm._e(),_c('div',{staticClass:"titleText"})])]),_c('div',{staticClass:"report-table"},[_vm._m(0),(_vm.situationList)?_c('div',{staticClass:"content"},[_c('ul',{staticClass:"box"},_vm._l((_vm.situationList),function(item){return _c('li',{key:item.order,staticClass:"list"},[_c('div',{staticClass:"subheadBox"},[_c('div',{staticClass:"subhead"},[_vm._v(" "+_vm._s(item.SType + "：" + item.SName)+" ")])]),_c('div',{staticClass:"range"},[_vm._v(" "+_vm._s(item.SOrder)+" "),_c('div',{staticClass:"rangeactive",class:{
                rangeright: item.SScore >= 60,
              },style:({
                width: item.SScore + '%',
              })},[_vm._v(" "+_vm._s(item.SScore)+" ")])])])}),0),(_vm.personalityMainData.ResultContent)?_c('div',{staticClass:"bodyBox"},[_c('div',{staticClass:"bodyItem",domProps:{"innerHTML":_vm._s(_vm.personalityMainData.ResultContent)}})]):_vm._e(),(_vm.personalityMainData.Character)?_c('div',{staticClass:"bodyBox"},[_c('div',{staticClass:"bodyTitle"},[_vm._v("性格特点")]),_c('div',{staticClass:"bodyItem",domProps:{"innerHTML":_vm._s(_vm.personalityMainData.Character)}})]):_vm._e(),(_vm.personalityMainData.ProfessionKeyWords)?_c('div',{staticClass:"bodyBox"},[_c('div',{staticClass:"bodyTitle"},[_vm._v("职业关键词")]),_c('div',{staticClass:"bodyArrItem"},[_vm._v(" "+_vm._s(_vm.personalityMainData.ProfessionKeyWords)+" ")])]):_vm._e(),(
          _vm.personalityMainData.SpecialtiesBKList ||
          _vm.personalityMainData.SpecialtiesZKList
        )?_c('div',{staticClass:"bodyBox"},[_c('div',{staticClass:"bodyTitle"},[_vm._v("匹配专业")]),(_vm.personalityMainData.SpecialtiesBKList)?_c('div',{staticClass:"bodyArr"},[_c('div',{staticClass:"bodyArrItem bodyArrItemtitle"},[_vm._v("本科专业")]),_vm._l((_vm.personalityMainData.SpecialtiesZKList),function(item,index){return _c('div',{key:index,staticClass:"bodyArrItem"},[_vm._v(" "+_vm._s(item)+" ")])})],2):_vm._e(),(_vm.personalityMainData.SpecialtiesZKList)?_c('div',{staticClass:"bodyArr"},[_c('div',{staticClass:"bodyArrItem bodyArrItemtitle"},[_vm._v("专科专业")]),_vm._l((_vm.personalityMainData.SpecialtiesBKList),function(item,index){return _c('div',{key:index,staticClass:"bodyArrItem"},[_vm._v(" "+_vm._s(item)+" ")])})],2):_vm._e()]):_vm._e(),(_vm.personalityMainData.DevelopmentDirection)?_c('div',{staticClass:"bodyBox"},[_c('div',{staticClass:"bodyTitle"},[_vm._v("发展方向")]),_c('div',{staticClass:"bodyArrItem",domProps:{"innerHTML":_vm._s(_vm.personalityMainData.DevelopmentDirection)}})]):_vm._e(),(_vm.personalityMainData.ProfessionList)?_c('div',{staticClass:"bodyBox"},[_c('div',{staticClass:"bodyTitle"},[_vm._v("适合职业")]),_c('div',{staticClass:"bodyArr"},_vm._l((_vm.personalityMainData.ProfessionList),function(item,index){return _c('div',{key:index,staticClass:"bodyArrItem"},[_vm._v(" "+_vm._s(item)+" ")])}),0)]):_vm._e()]):_vm._e()])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.IsVipUser==false),expression:"IsVipUser==false"}],staticClass:"buy_vip_box"},[_c('div',{staticClass:"btitle"},[_vm._v("您还不是VIP用户，暂无权限查看测评报告！")]),_c('div',{staticClass:"open_btn",on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/openvip');}}},[_vm._v("开通 VIP")])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"head"},[_c('div',{staticClass:"top"},[_vm._v("测试情况")]),_c('div',{staticClass:"bottom"},[_vm._v("总分100分")])])}]

export { render, staticRenderFns }