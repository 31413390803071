<template>
  <div class="mainBox">
    <div class="titleBox">
      <div class="contentBox">
        <div class="titleText">
          升学，从某种意义上说就是一种博弈。28种升学路径，就是抓在我们手中的28张扑克牌，通过我们的组合，发挥扑克牌的最大价值，在千军万马中最先冲进理想大学的城堡。进行升学途径测评，就是要找到升学的最佳组合方式，找准职业方向，增加录取机会，降低高考的难度，节省在大学费用，决胜高考，赢得人生。
        </div>
      </div>
    </div>
    <div>
      <img v-if="clientWidth <= 750" class="imgbox" src="@/assets/test/enterTable.png" />
      <img v-else class="imgbox" src="@/assets/test/enterTablepc.png" />
    </div>
    <div class="report-table">
      <template v-if="enterStudyData && enterStudyData.Recommend">
        <div class="head">
          <div class="top">系统推荐升学路径</div>
          <div class="bottom">
            根据你的选择，以下升学路径符合你的条件，可根据情况选择：
          </div>
        </div>
        <div class="bodyBox" v-for="(item, index) in enterStudyData.Recommend" :key="index">
          <div class="bodyTitle">{{ item.PathName }}</div>
          <div class="bodyItem" v-html="item.PathDesc"></div>
          <div class="bodyItem ProsBox" v-if="item.BaseCondition">
            <div class="itemTitle">基本条件</div>
            <div v-html="item.BaseCondition"></div>
          </div>
          <div class="bodyItem ProsBox" v-if="item.PathTip">
            <div class="itemTitle">报考提示</div>
            <div v-html="item.PathTip"></div>
          </div>
        </div>
      </template>
      <template v-if="ResultAnswersShowMore">
        <template v-if="enterStudyData && enterStudyData.NotRecommend">
          <div class="head">
            <div class="top">不推荐的升学路径</div>
            <div class="bottom">以下升学途径系统不予推荐：</div>
          </div>
          <div class="bodyBox" v-for="item in enterStudyData.NotRecommend" :key="item.PathDesc">
            <div class="bodyTitle">{{ item.PathName }}</div>
            <div class="bodyItem" v-html="item.PathDesc"></div>
            <div class="bodyItem ProsBox" v-if="item.PointNameList">
              <div class="itemTitle itemTitle2">不推荐原因</div>
              <div>
                根据测试的结果，因
                <span v-for="(ite, index1) in item.PointNameList" :key="index1">{{
                  ite + (index1 === item.PointNameList.length - 1 ? "" : "、")
                }}</span>
                等原因不推荐就读。当然，但这并不能代表不可以就读，但建议你根据情况慎重选择。
              </div>
            </div>
          </div>
        </template>
        <template v-if="enterStudyData && enterStudyData.NotMatch">
          <div class="head">
            <div class="top">不符合的升学路径</div>
            <div class="bottom">你不具备以下升学途径的条件：</div>
          </div>
          <div class="bodyBox" v-for="item in enterStudyData.NotMatch" :key="item.PathDesc">
            <div class="bodyTitle">{{ item.PathName }}</div>
            <div class="bodyItem" v-html="item.PathDesc"></div>
            <div class="bodyItem ProsBox" v-if="item.PointNameList">
              <div class="itemTitle itemTitle3">不符合原因</div>
              <div>
                根据测试的结果，因
                <span v-for="(ite, index1) in item.PointNameList" :key="index1">{{
                  ite + (index1 === item.PointNameList.length - 1 ? "" : "、")
                }}</span>
                等原因，本升学途径不适合你。请选择推荐升学路径或直接参加裸分高考。
              </div>
            </div>
          </div>
        </template>
        <div class="footer">
          由于我们仅使用最基础的8个指标进行测试，所以结果不可能100%的准确。例如，体检有数十项具体指标，我们不可能把所有指标都让考生填写一遍，只能把最常见、最方便测量的指标罗列出来，成绩也只能进行预估，所以有误差在所难免。在此慎重声明，我们为考生提供参考，但不对测评结果负责。
        </div>
      </template>

      <div class="no-print" v-if="enterStudyData">
        <div v-if="!ResultAnswersShowMore" class="lookMore" @click="lookMore">
          查看我的不推荐路径<img class="btnImg" src="@/assets/test/btn-b.png" alt="" />
        </div>
        <div v-if="ResultAnswersShowMore" class="lookMore" @click="ResultAnswersShowMore = false">
          收起<img class="btnImg" src="@/assets/test/btn-t.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api/config'
// import { TestUpgradePathInfo } from "@/api/test/test";
import { getStore } from "@/utils/utils";

export default {
  name: "test-result",
  props: {
    clientWidth: Number,
  },
  data() {
    return {
      //personality test
      enterStudyData: null,
      personalityType: "",
      ResultAnswersShowMore: false,
    };
  },
  computed: {},
  watch: {
    $route: function () {
      this.init();
    },
  },
  created() { },
  mounted() {
    this.init();
  },
  methods: {
    lookMore() {
      this.ResultAnswersShowMore = true;
    },
    init() {
      let loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0,0,0,0.5)'
      })
      
      let temp = getStore('LoginData')
      if (temp) {

        //人格测试
        API.User.TestUpgradePathInfo({
          UserId: JSON.parse(temp).UserId,
          AnswerList: this.$ls.get("test-enterPath-answerList"),
        }).then((res) => {
          this.enterStudyData = res.data;
          loading.close()
        }).catch((err) => {
          loading.close()
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
@media print {}

.mainBox {
  .titleBox {
    margin: 20px 0px;
    color: #fff;

    .contentBox {
      width: 100%;

      .titleText {
        font-size: 14px;
        line-height: 24px;
        height: auto;
      }
    }
  }

  .imgbox {
    width: 100%;
  }

  .report-table {
    background: #fff;
    border-radius: 20px;
    margin-top: 20px;
    padding: 20px;

    .head {
      margin-bottom: 20px;

      .top {
        height: 22px;
        font-size: 20px;
        font-weight: bold;
        line-height: 22px;
        text-align: center;
        margin-bottom: 16px;
      }

      .bottom {
        font-size: 14px;
        line-height: 20px;
        color: #a8a8bb;
      }
    }

    .contentTitle {
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      line-height: 23px;
      color: #22b26a;
    }

    .bodyBox {
      padding-top: 12px;
      color: #2a2b4e;

      .bodyTitle {
        position: relative;
        font-size: 16px;
        font-weight: bold;
        margin: 0 0 12px 16px;

        &::before {
          position: absolute;
          left: -16px;
          content: "";
          width: 8px;
          height: 16px;
          background: #444cf0;
          border-radius: 4px;
        }
      }

      .bodyItem {
        line-height: 24px;
        font-size: 14px;
      }

      .ProsBox {
        margin-bottom: 20px;

        .itemTitle {
          height: 45px;
          font-size: 16px;
          font-weight: bold;
          line-height: 40px;
          color: #2a2b4e;
          text-align: center;
          height: 40px;
          line-height: 40px;
          background: rgba(34, 178, 106, 0.2);
          margin: 12px 0;
        }

        .itemTitle2 {
          background: rgba(255, 190, 93, 0.2);
        }

        .itemTitle3 {
          background: rgba(232, 70, 70, 0.2);
        }
      }

      .bodyArr {
        display: flex;
        flex-wrap: wrap;
        background: #f9f9f9;
        margin-bottom: 12px;
        border-radius: 10px;
      }

      .bodyArrItem {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 4px;
        height: 60px;
        width: 88px;
        background: #fff;
        border-radius: 10px;
        line-height: 16px;
        font-size: 12px;
        text-align: center;
        color: #2a2b4e;
      }

      .bodyArrItemtitle {
        width: 100%;
        height: 60px;
        background: rgba(34, 178, 106, 0.2);
        border-radius: 10px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        margin: 0px 0px 10px;
        color: #22b26a;
        line-height: 60px;

        &>.span {
          margin-left: 10px;
        }
      }
    }
  }
}

.lookMore {
  width: 160px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  color: #2a2b4e;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  margin: 0 auto;

  .btnImg {
    width: 15px;
    height: 8px;
    margin-left: 6px;
  }
}

.titleItem {
  height: 33px;
  font-size: 12px;
  line-height: 33px;
  color: #a8a8bb;
}

.percentAll {
  text-align: center;
}

.footer {
  color: #e84646;
  line-height: 24px;
  margin-top: 20px;
}
</style>
