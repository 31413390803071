<template>
  <div class="open-member">
    <div class="title">开通会员，可查看测评报告</div>
    <div class="box" @click="openMember">
      去开通会员
    </div>
  </div>
</template>

<script>
export default {
  name: "OpenMember",
  data () {
    return {}
  },
  mounted () {

  },
  methods: {
    // 开通会员
    openMember () {
      this.$router.push('/VIP/index')
    }
  }
}
</script>

<style scoped lang="less">
.open-member {

  width: 1200px;
  height: 400px;
  padding: 80px 0;
  margin: 200px auto;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 0 10px 0 rgba(57, 105, 246, 0.1);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 5%, rgba(253,253,183,0.5) 30%, #fff6ae 80%);

  position: relative;
  z-index: 2;
  .title {
    color: #009f00;
    font-size: 35px;
  }
  .box {
    width: 300px;
    padding: 10px;
    margin: 100px auto 0;
    color: #fffde0;
    font-size: 40px;
    font-weight: 700;
    border-radius: 10px;
    background-color: rgba(255, 195, 41, 0.8);
    cursor: pointer;

  }
}

</style>
