<template>
  <div class="mainBox">
    <div v-show="IsVipUser==true">
      <div class="titleBox">
      <div class="headimg">
        <img class="imgtitle" :src="MBTIContent.ResultHeadPhoto" />
      </div>
      <div class="contentBox">
        <div class="titleName">
          <!-- 探险家 -->
          {{ MBTIContent.ResultAlias }}
          <span v-if="MBTIContent.ResultCode">{{
            MBTIContent.ResultCode
          }}</span>
        </div>
        <div class="titleText">
          {{ MBTIContent.ResultDescription }}
          <!-- 在一天中我会变化。 醒来时是一个人，睡觉时却明明是另一个人。 -->
        </div>
      </div>
    </div>
    <div class="report-table">
      <div class="head">
        <div class="top">
          <!-- MBTI职业性格测评结论：{{ MBTIContent.ResultCode }}， -->
          性格特征指数：{{ MBTIContent.Number }}
        </div>
        <div class="bottom">
          指数越高此性格特征越明显，最大值为100，多数人指数在60-85之间
        </div>
      </div>
      <div class="content" v-if="MBTIContent">
        <ul class="box">
          <li class="list">
            <div class="subheadBox">
              <div
                class="subhead"
                :class="
                  MBTIContent.NumberE > MBTIContent.NumberI ? 'subactive' : ''
                "
              >
                外向（E） {{ MBTIContent.NumberE }}
              </div>
              <div
                class="subhead"
                :class="
                  MBTIContent.NumberE > MBTIContent.NumberI ? '' : 'subactive'
                "
              >
                {{ MBTIContent.NumberI }} 内向（I）
              </div>
            </div>
            <div class="range">
              E
              <div
                class="rangeactive"
                :class="
                  MBTIContent.NumberE > MBTIContent.NumberI ? '' : 'rangeright'
                "
                :style="{
                  width:
                    (MBTIContent.NumberE > MBTIContent.NumberI
                      ? MBTIContent.NumberE
                      : MBTIContent.NumberI) + '%',
                }"
              >
                I
              </div>
            </div>
          </li>
          <li class="list">
            <div class="subheadBox">
              <div
                class="subhead"
                :class="
                  MBTIContent.NumberS > MBTIContent.NumberN ? 'subactive' : ''
                "
              >
                感觉（S）{{ MBTIContent.NumberS }}
              </div>
              <div
                class="subhead"
                :class="
                  MBTIContent.NumberS > MBTIContent.NumberN ? '' : 'subactive'
                "
              >
                {{ MBTIContent.NumberN }} 直觉（N）
              </div>
            </div>
            <div class="range">
              S
              <div
                class="rangeactive"
                :class="
                  MBTIContent.NumberS > MBTIContent.NumberN ? '' : 'rangeright'
                "
                :style="{
                  width:
                    (MBTIContent.NumberS > MBTIContent.NumberN
                      ? MBTIContent.NumberS
                      : MBTIContent.NumberN) + '%',
                }"
              >
                N
              </div>
            </div>
          </li>
          <li class="list">
            <div class="subheadBox">
              <div
                class="subhead"
                :class="
                  MBTIContent.NumberT > MBTIContent.NumberF ? 'subactive' : ''
                "
              >
                思维（T）{{ MBTIContent.NumberT }}
              </div>
              <div
                class="subhead"
                :class="
                  MBTIContent.NumberT > MBTIContent.NumberF ? '' : 'subactive'
                "
              >
                {{ MBTIContent.NumberF }} 情感（F）
              </div>
            </div>
            <div class="range">
              T
              <div
                class="rangeactive"
                :class="
                  MBTIContent.NumberT > MBTIContent.NumberF ? '' : 'rangeright'
                "
                :style="{
                  width:
                    (MBTIContent.NumberT > MBTIContent.NumberF
                      ? MBTIContent.NumberT
                      : MBTIContent.NumberF) + '%',
                }"
              >
                F
              </div>
            </div>
          </li>
          <li class="list">
            <div class="subheadBox">
              <div
                class="subhead"
                :class="
                  MBTIContent.NumberJ > MBTIContent.NumberP ? 'subactive' : ''
                "
              >
                判断（J）{{ MBTIContent.NumberJ }}
              </div>
              <div
                class="subhead"
                :class="
                  MBTIContent.NumberJ > MBTIContent.NumberP ? '' : 'subactive'
                "
              >
                {{ MBTIContent.NumberP }} 知觉（P）
              </div>
            </div>
            <div class="range">
              J
              <div
                class="rangeactive"
                :class="
                  MBTIContent.NumberJ > MBTIContent.NumberP ? '' : 'rangeright'
                "
                :style="{
                  width:
                    (MBTIContent.NumberJ > MBTIContent.NumberP
                      ? MBTIContent.NumberJ
                      : MBTIContent.NumberP) + '%',
                }"
              >
                P
              </div>
            </div>
          </li>
        </ul>
        <div class="bodyBox" v-if="MBTIContent.ResultMeanings">
          <div class="bodyTitle">{{ MBTIContent.ResultCode }}性格主要特点</div>
          <div class="bodyItem" v-html="MBTIContent.ResultMeanings"></div>
        </div>
        <div
          class="bodyBox"
          v-if="
            MBTIContent.ResultCelebrities &&
            MBTIContent.ResultCelebrities.length > 0
          "
        >
          <div class="bodyTitle">他们和你是同类人</div>
          <div class="bodyItem">
            <div
              class="CeleBox"
              v-for="(item, index) in MBTIContent.ResultCelebrities"
              :key="index"
            >
              <div class="headimg">
                <img class="imgtitle" :src="item.ImageUrl" />
              </div>
              <div class="contentBox">
                <div class="titleName">
                  {{ item.Name }}
                </div>
                <div class="titleText">
                  {{ item.Description }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="bodyBox" v-if="MBTIContent.ResultProfessions">
          <div class="bodyTitle">适合职业</div>
          <div class="bodyArr">
            <div
              class="bodyArrItem"
              v-for="(item, index) in MBTIContent.ResultProfessions"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div class="bodyBox" v-if="MBTIContent.ResultCharacter">
          <div class="bodyTitle">个性特征描述</div>
          <div class="bodyItem" v-html="MBTIContent.ResultCharacter"></div>
        </div>
        <div class="bodyBox" v-if="MBTIContent.ResultPatternOfSolution">
          <div class="bodyTitle">问题解决方式</div>
          <div
            class="bodyItem"
            v-html="MBTIContent.ResultPatternOfSolution"
          ></div>
        </div>
        <div class="bodyBox" v-if="MBTIContent.ResultInclinationOfEnvironment">
          <div class="bodyTitle">工作中的优劣势</div>
          <div
            class="bodyItem"
            v-html="MBTIContent.ResultInclinationOfEnvironment"
          ></div>
        </div>
        <div class="bodyBox" v-if="MBTIContent.ResultContributions">
          <div class="bodyTitle">对组织的贡献</div>
          <div class="bodyItem" v-html="MBTIContent.ResultContributions"></div>
        </div>
        <div class="bodyBox" v-if="MBTIContent.ResultCharacter">
          <div class="bodyTitle">领导风格</div>
          <div
            class="bodyItem"
            v-html="MBTIContent.ResultPatternOfLeadership"
          ></div>
        </div>
        <div class="bodyBox" v-if="MBTIContent.ResultDevelopmentAdvices">
          <div class="bodyTitle">个人发展建议</div>
          <div
            class="bodyItem"
            v-html="MBTIContent.ResultDevelopmentAdvices"
          ></div>
        </div>
      </div>
    </div>
    </div>
    <div class="buy_vip_box" v-show="IsVipUser==false">
        <div class="btitle">您还不是VIP用户，暂无权限查看测评报告！</div>
        <div class="open_btn" @click.prevent="$router.push('/openvip');">开通 VIP</div>
    </div>
  </div>
</template>

<script>
import API from '@/api/config'
// import { TestMBTIInfo } from "@/api/test/test";

export default {
  name: "test-result",
  data() {
    return {
      clientWidth: document.body.clientWidth,
      //MBTI
      MBTIContent: "",
      IsVipUser:null,
    };
  },
  computed: {},
  watch: {
    $route: function () {
      this.init();
    },
  },
  created() {},
  mounted() {
    this.init();
    // window.history.back(-3)
  },
  methods: {
    num(param) {
      return param / 10;
    },
    init() {
      let loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0,0,0,0.5)'
      })
      let MBTIAnswer = this.$ls.get("testAnswer");
      //MBTI测试
      API.User.TestMBTIInfo({
        AnswerList: MBTIAnswer,
      })
        .then((res) => {
          if(res.code==0){
            this.IsVipUser = true;
            this.MBTIContent = res.data;
          }
          else if(res.code==113){
            this.IsVipUser = false;
          }
          else{
            this.$message.error(res.message);
          }
          loading.close()
        })
        .catch((err) => {
          loading.close()
        });
    },
   
  },
};
</script>

<style scoped lang="less">
@media print {
}
.mainBox {
  .titleBox {
    display: flex;
    margin-bottom: 20px;
    color: #fff;
    .headimg {
      background: #fff;
      height: 96px;
      width: 96px;
      border-radius: 50%;
      margin-right: 20px;
      .imgtitle {
        height: 96px;
        width: 96px;
        border-radius: 50%;
      }
    }
    .contentBox {
      height: 96px;
      width: calc(100% - 116px);
      .titleName {
        font-size: 20px;
        font-weight: 800;
        line-height: 26px;
        span {
          display: inline-block;
          padding: 0px 8px;
          height: 20px;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          background: #ffffff;
          border-radius: 10px;
          color: #444cf0;
          // margin-left: 8px;
        }
      }
      .titleText {
        margin-top: 16px;
        font-size: 14px;
        line-height: 20px;
        height: 54px;
      }
    }
  }
  .CeleBox {
    display: flex;
    margin-bottom: 10px;
    .headimg {
      background: #fff;
      height: 60px;
      width: 60px;
      border-radius: 50%;
      margin-right: 20px;
      .imgtitle {
        height: 60px;
        width: 60px;
        border-radius: 50%;
      }
    }
    .contentBox {
      height: auto;
      width: calc(100% - 80px);
      .titleName {
        font-size: 14px;
        font-weight: 800;
        line-height: 20px;
      }
      .titleText {
        display: inline-block;
        margin-top: 10px;
        font-size: 12px;
        line-height: 20px;
        // height: 25px;
        color: #fff;
        border-radius: 4px;
        padding: 4px 8px;
        background: linear-gradient(133deg, #ff823a 0%, #ff735d 100%);
      }
    }
  }
  .report-table {
    background: #fff;
    border-radius: 20px;
    padding: 20px;
    .OrgNamebox {
      text-align: center;
      font-size: 10px;
      color: #a8a8bb;
      margin-bottom: 10px;
    }
    .head {
      margin-bottom: 20px;
      .top {
        height: 22px;
        font-size: 16px;
        font-weight: bold;
        line-height: 22px;
        color: #444cf0;
        margin-bottom: 8px;
      }
      .bottom {
        font-size: 14px;
        line-height: 20px;
        color: #a8a8bb;
      }
    }
    .content {
      .box {
        .list {
          margin-bottom: 16px;
          .subheadBox {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 4px;
            font-size: 12px;
            .subhead {
              color: #a8a8bb;
            }
            .subactive {
              color: #ff823a;
            }
          }
          .range {
            width: 100%;
            height: 8px;
            line-height: 8px;
            text-align: center;
            background: #e3e3ee;
            border-radius: 4px;
            font-size: 6px;
            color: transparent;
            position: relative;
            .rangeactive {
              position: absolute;
              top: 0;
              height: 8px;
              background: linear-gradient(270deg, #ff823a 0%, #ff735d 100%);
              border-radius: 4px;
            }
            .rangeright {
              right: 0;
            }
          }
        }
      }
      .bodyBox {
        padding-top: 12px;
        .bodyTitle {
          position: relative;
          font-size: 16px;
          font-weight: bold;
          margin: 0 0 12px 16px;
          &::before {
            position: absolute;
            left: -16px;
            content: "";
            width: 8px;
            height: 16px;
            background: #444cf0;
            border-radius: 4px;
          }
        }
        .bodyItem {
          line-height: 24px;
          font-size: 14px;
        }
        .bodyArr {
          display: flex;
          flex-wrap: wrap;
          .bodyArrItem {
            display: inline-block;
            margin: 10px 4px;
            height: 32px;
            background: #f9f9f9;
            border-radius: 16px;
            padding: 0px 16px;
            line-height: 32px;
            font-size: 12px;
            text-align: center;
          }
          .bodyArrItemtitle {
            width: 100%;
            font-weight: bold;
            font-size: 14px;
            color: #a8a8bb;
          }
        }
      }
    }
  }
}
.buy_vip_box{
    max-width: 1000px;
    margin: auto;
    min-height: 500px;
    .btitle{
        color: #fff;
        font-size: 26px;
        text-align: center;
        margin: 100px auto 50px;
    }
    .open_btn{
        width: 200px;
        height: 46px;
        line-height: 46px;
        color: rgba(var(--themecolor),0.85);
        background-color: #fff;
        font-size: 22px;
        letter-spacing: 5px;
        border-radius: 5px;
        margin: auto;
        text-align: center;
        font-weight: bold;
        margin-bottom: 50px;
        cursor: pointer;
    }
}
</style>
