<template>
  <div
    class="test-result"
    :class="{
      testMbti: testType === '1',
      testHoland: testType === '2',
      testAnswer: testType === '3',
      testSubject: testType === '4',
      testEnter: testType === '5',
    }"
    ref="print"
  >
    <div class="bgimg">
      <img class="imgBox" src="@/assets/test/bgimg.png" />
    </div>
    <div v-if="studentInfo" class="testBox">
      <div class="title" v-if="testType === '1'">MBTI职业测试</div>
      <div class="title" v-else-if="testType === '2'">霍兰德性格测评</div>
      <div class="title" v-else-if="testType === '3'">中学生人格测评</div>
      <div class="title" v-else-if="testType === '4'">新高考选科测评</div>
      <div class="title" v-else-if="testType === '5'">升学路径测评</div>
      <div
        class="print no-print"
        v-show="clientWidth >= 750"
        @click="printPage"
      >
        打印报告
      </div>
      <div class="OrgNamebox">
        <div v-if="studentInfo" class="nameBox">
          {{ studentInfo.NickName }}·{{ studentInfo.LoginName }}
        </div>
        <div v-if="$ls.get('OrgName')">
          本测试结果由{{ $ls.get("OrgName") }}提供
        </div>
      </div>
      <mbtiResult v-if="testType === '1'"></mbtiResult>
      <holandResult v-else-if="testType === '2'"></holandResult>
      <personalityResult v-else-if="testType === '3'"></personalityResult>
      <subjectResult
        ref="subjectResult"
        v-else-if="testType === '4'"
      ></subjectResult>
      <enterstudyResult
        ref="enterstudyResult"
        v-else-if="testType === '5'"
        :clientWidth="clientWidth"
      ></enterstudyResult>
      <div class="other-tips" v-else>暂无数据...</div>
    </div>
    <open-member v-else></open-member>
    <div class="footerbox no-print">已经到底啦~</div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import mbtiResult from "./result/MBTIresult.vue";
  import holandResult from "./result/holandResult.vue";
  import personalityResult from "./result/PersonalityResult.vue";
  import subjectResult from "./result/subjectResult.vue";
  import enterstudyResult from "./result/enterstudyResult.vue"
  import OpenMember from './custom-components/OpenMember'
  import { getStore } from "@/utils/utils";

  export default {
    name: "test-result",
    components: {
      mbtiResult,
      holandResult,
      personalityResult,
      subjectResult,
      enterstudyResult,
      OpenMember
    },
    data() {
      return {
        clientWidth: document.body.clientWidth,
        testType: this.$route.query.testType,
        studentInfo: null,
      };
    },
    computed: {
      // ...mapGetters([
      //   "roles"
      // ])
    },
    // watch: {
    //   $route: function () {
    //     this.init();
    //   },
    // },
    created() {},
    mounted() {
      this.getStudentDetail()
      
      const that = this;
      window.onresize = () => {
        return (() => {
          window.screenWidth = document.body.clientWidth;
          that.clientWidth = window.screenWidth;
        })()
      }
    },
    methods: {
      num(param) {
        return param / 10;
      },
      getStudentDetail() {
        let temp = getStore('LoginData')

        if (!temp) {
          this.$message.info('请先登录！')
          return
        }
        this.studentInfo = JSON.parse(temp)

      },
      printPage() {
        if (this.testType === "4") {
          this.$refs.subjectResult.lookMore();
          setTimeout(() => {
            this.printPageFn();
          }, 100);
        } else if(this.testType === "5") {
          this.$refs.enterstudyResult.lookMore();
          setTimeout(() => {
            this.printPageFn();
          }, 100);
        } else {
          this.printPageFn();
        }
        // window.print();
      },
      printPageFn() {
        this.$print(this.$refs.print);
        this.$nextTick(() => {
          let arr = document.getElementsByClassName("isNeedRemove");
          if (arr.length) arr[0].remove();
        });
      },
    },
  };
</script>

<style scoped lang="less">
  .testMbti {
    background: linear-gradient(180deg, #444cf0 0%, #7d44f0 100%);
  }
  .testHoland {
    background: linear-gradient(180deg, #e84646 0%, #d31d1d 100%);
  }
  .testAnswer {
    background: linear-gradient(180deg, #023282 0%, #012c72 100%);
  }
  .testSubject {
    background: linear-gradient(180deg, rgba(var(--themecolor),0.75), rgba(var(--themecolor),1) 100%);
  }
  .testEnter{
    background: linear-gradient(180deg, #F4D25E 0%, #F95940 100%);
  }
  .test-result {
    position: relative;
    padding: 15px;
    min-height: 100vh;

    -webkit-print-color-adjust: exact;

    // height: 100%;
    overflow: hidden;
    .bgimg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 1;
      .imgBox {
        width: 100%;
      }
    }
    .title {
      height: 50px;
      font-size: 18px;
      font-weight: 800;
      line-height: 50px;
      color: #ffffff;
      text-align: center;
      margin-bottom: 30px;
    }
    .OrgNamebox {
      color: #fff;
      text-align: center;
      margin-bottom: 20px;
      font-size: 12px;
      .nameBox {
        font-size: 16px;
        font-weight: bold;
        line-height: 32px;
      }
    }
    .print {
      text-align: center;
      width: 120px;
      height: 40px;
      background: #ffffff;
      border-radius: 20px;
      font-size: 15px;
      letter-spacing: 2px;
      font-weight: bold;
      line-height: 40px;
      color: rgb(var(--themecolor));
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 0;
    }

    .testBox {
      max-width: 1200px;
      margin: 0 auto;
      position: relative;
      z-index: 2;
    }
  }
  .footerbox {
    color: #fff;
    text-align: center;
    font-size: 12px;
    line-height: 33px;
    margin: 20px;
  }
</style>
