var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mainBox"},[_c('div',{staticClass:"titleBox"},[_c('div',{staticClass:"contentBox"},[(_vm.personalityType)?_c('div',{staticClass:"titleName"},[_vm._v(" "+_vm._s(_vm.personalityType)+" ")]):_vm._e(),_c('div',{staticClass:"titleText"},[_vm._v("经系统AI匹配 三组学科最适合你")])])]),_c('div',{staticClass:"report-table"},[_vm._m(0),(_vm.situationList)?_c('div',{staticClass:"content"},[_c('ul',{staticClass:"box"},_vm._l((_vm.situationList),function(item,index){return _c('li',{key:index,staticClass:"list"},[_c('div',{staticClass:"subheadBox"},[_c('div',{staticClass:"subhead"},[_vm._v(" "+_vm._s(item.Name)+" ")])]),_c('div',{staticClass:"range"},[_vm._v(" "+_vm._s(item.number)+" "),_c('div',{staticClass:"rangeactive",class:{
                rangeright: item.number >= 60,
              },style:({
                width: item.number + '%',
              })},[_vm._v(" "+_vm._s(item.number)+" ")])])])}),0),(_vm.SelSubjectMainData.ResultAnswers)?[_vm._l((_vm.SelSubjectMainData.ResultAnswers),function(item,index){return _c('div',{key:index},[(
              (_vm.ResultAnswersShowMore &&
                index <= _vm.SelSubjectMainData.ResultAnswers.length - 1) ||
              (!_vm.ResultAnswersShowMore && index === 0)
            )?[(index > 0)?_c('div',{staticClass:"contentTitle"},[_c('div',[_vm._v("备选"+_vm._s(index))]),_c('div',[_vm._v(_vm._s(item.Answer))])]):_vm._e(),(item.Character)?_c('div',{staticClass:"bodyBox"},[_c('div',{staticClass:"bodyTitle"},[_vm._v("学科特点")]),_c('div',{staticClass:"bodyItem",domProps:{"innerHTML":_vm._s(item.Character)}})]):_vm._e(),(item.StatisticData && item.StatisticData.length > 0)?_c('div',{staticClass:"bodyBox"},[_c('div',{staticClass:"bodyTitle"},[_vm._v("覆盖率")]),_c('div',{staticClass:"titleItem"},[_vm._v(" 在该省份的专业选考要求中满足要求的占比 ")]),_c('div',{staticClass:"titleItem percentAll"},[_vm._v(" 可报专业比例："+_vm._s(_vm.yuantofen(item.PercentAboveAll, 100))+"% ")]),_vm._l((item.StatisticDataSub),function(ite,index1){return _c('div',{key:index1,staticClass:"bodyArr"},[_c('div',{staticClass:"bodyArrItem bodyArrItemtitle"},[_c('span',[_vm._v(_vm._s(ite.Name))]),_c('span',{staticClass:"span"},[_vm._v(_vm._s(_vm.yuantofen(ite.Percent, 100))+"%")])]),_vm._l((ite.ChildNodes),function(it,index2){return _c('div',{key:index2,staticClass:"bodyArrItem"},[_c('div',[_vm._v(" "+_vm._s(it.Name)+" "),_c('div',[_vm._v(_vm._s(_vm.yuantofen(it.Percent, 100))+"%")])])])})],2)}),(
                  item.StatisticData.length > 3 &&
                  item.StatisticDataSub.length <= 3
                )?_c('div',[_c('div',{staticClass:"lookMore",on:{"click":function($event){return _vm.lookMoreStatistic(index)}}},[_vm._v(" 查看更多 ")])]):_vm._e()],2):_vm._e(),(item.Pros || item.Cons)?_c('div',{staticClass:"bodyBox"},[_c('div',{staticClass:"bodyTitle"},[_vm._v("选科优劣势")]),(item.Pros)?_c('div',{staticClass:"bodyItem ProsBox"},[_c('div',{staticClass:"itemTitle"},[_vm._v("优势")]),_c('div',{domProps:{"innerHTML":_vm._s(item.Pros)}})]):_vm._e(),(item.Cons)?_c('div',{staticClass:"bodyItem ProsBox"},[_c('div',{staticClass:"itemTitle"},[_vm._v("劣势")]),_c('div',{domProps:{"innerHTML":_vm._s(item.Cons)}})]):_vm._e()]):_vm._e()]:_vm._e()],2)}),(!_vm.ResultAnswersShowMore)?_c('div',[_c('div',{staticClass:"lookMore",on:{"click":_vm.lookMoreResultAnswers}},[_vm._v(" 查看更多备选组合 ")])]):_vm._e()]:_vm._e()],2):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"head"},[_c('div',{staticClass:"top"},[_vm._v("学科兴趣度")]),_c('div',{staticClass:"bottom"},[_vm._v("总分100分")])])}]

export { render, staticRenderFns }